import "jquery.formelements";

$(function () {
    $(".formelement-select-purple").formelements({
        classlist: "formelements_item select select select--purple",
    });

    $(".formelement-select-blue").formelements({
        classlist: "formelements_item select select select--blue",
    });

    $(".formelement-select-grey").formelements({
        classlist: "formelements_item select select select--grey",
    });

    $("#offices-contact").change(function () {
        var val = $(this).val();
        window.location.href = val;
    });

    $("#submenu").change(function () {
        var val = $(this).val();
        window.location.href = val;
    });
});
