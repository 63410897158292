import Helpers from "./helpers.js";
import jsCookie from "js-cookie";

var vh = null;
var $view = null;
var allJobs = null;
var filter =
    jsCookie.get("filter") !== undefined
        ? jsCookie.getJSON("filter")
        : { text: "", trade: "", location: "" };

export function init(vh_ref) {
    vh = vh_ref;
    $view = vh.pageView("search");
    $("#search-text").val(filter.text);
    bind();
}

function userTriggeredSearch() {
    filter = getFilterData();
    triggerSearch();
}

function bind() {
    $(".js-bot-search.bot-view-show-all").on("click", function () {
        $("#search-text").val("");
        $("#search-trade").val("placeholder");
        $("#search-location").val("placeholder");
        $("#search-trade").formelements("refresh");
        $("#search-location").formelements("refresh");
        // userTriggeredSearch();
    });
    $(".js-bot-search").on("click", userTriggeredSearch);
    $("#search-trade, #search-location").on("change", userTriggeredSearch);
}

export function triggerSearch() {
    hideResult();
    Helpers.setTypedViewText("Söker efter jobb...", $view);
    showLoader();

    if (allJobs == null || allJobs.length < 1) {
        var request = $.ajax({
            url: "/wp-json/clockwork/v1/job-ads/",
            type: "GET",
        });

        request.done(function (data) {
            allJobs = data;
            hideLoader();
            var availableFilters = getAvailableFilters(allJobs);
            initDropdowns(availableFilters);

            render(applyFilter(data, filter), filter);

            $(".js-bot-search.bot-view-show-all").text(
                "Visa alla jobb (" + data.length + " st)"
            );
        });

        request.fail(function (jqXHR) {
            console.log(jqXHR.responseText);
            //TODO:Notify user..
        });
    } else render(applyFilter(allJobs, filter), filter);
}

function getFilterData() {
    var $text = $("#search-text");
    var $trade = $("#search-trade");
    var $location = $("#search-location");

    var filter = {
        text: $text.val(),
        trade: $trade.val(),
        location: $location.val(),
    };

    jsCookie.set("filter", filter, { expires: 14 });

    return filter;
}

function showLoader() {
    $(".bot-loader").addClass("loading");
}

function hideLoader() {
    $(".bot-loader").removeClass("loading");
}

function showResult(data) {
    $(".bot-view-joblisting__toolbar__jobcount").html(
        "Våra jobb (" + data.length + ")"
    );
    var presentation = $(".bot-view-page-search").data("adpresentation");
    $(".bot-view-joblisting__item").remove();
    var descriptionTargetString = "Dina arbetsuppgifter";
    $.each(data, function (index, item) {
        var descriptionText = "";

        if ($.isArray(item.descriptions.description)) {
            var description = !Helpers.isEmpty(item.descriptions)
                ? item.descriptions.description.filter(function (desc) {
                      if (desc === undefined) {
                          return false;
                      }

                      if (desc.descriptionheader === undefined) {
                          return false;
                      }

                      return Helpers.caseInsensitiveSearch(
                          desc.descriptionheader,
                          descriptionTargetString
                      );
                  })
                : false;

            if (description === false || description.length < 1) {
                descriptionText =
                    item.descriptions.description !== undefined &&
                    item.descriptions.description[0] !== undefined
                        ? item.descriptions.description[0].descriptiontext
                        : "";
            } else {
                descriptionText =
                    !Helpers.isEmpty(description) || description
                        ? description[0].descriptiontext
                        : "";
            }
        } else {
            //var description = Helpers.caseInsensitiveSearch(item.descriptions.description.descriptionheader, descriptionTargetString)

            if (
                !Helpers.isEmpty(item.descriptions.description) &&
                !Helpers.isEmpty(item.descriptions.description.descriptiontext)
            ) {
                descriptionText = item.descriptions.description.descriptiontext;
            } else {
                console.log(item);
                descriptionText = "";
            }
        }

        // if(description !== false || description.length < 1) {
        // 	descriptionText = item.descriptions.description !== undefined && item.descriptions.description[0] !== undefined ? item.descriptions.description[0].descriptiontext : ''
        // } else {
        //     descriptionText = !Helpers.isEmpty(description) || description ? description[0].descriptiontext : '';
        // }

        $(".bot-view-joblisting").append(
            '<div class="bot-view-joblisting__item s-col-12 m-col-12 col-12">' +
                '  <div class="bot-view-joblisting__item__content s-col-12 m-col-12 col-9">' +
                '    <h2><a href="' +
                presentation +
                item.id +
                '/">' +
                item.title +
                "</a></h2>" +
                "    <p>" +
                Helpers.shorten(descriptionText, 160) +
                "..." +
                "</p>" +
                "	 <span>" +
                item.municipality +
                ", " +
                item.pubdate +
                "</span>" +
                "  </div>" +
                '  <div class="s-col-12 m-col-12 l-col-12 col-3 bot-view-joblisting__item__readmore">' +
                '    <div class="s-col-3 m-col-3 l-col-3 col-12">' +
                '      <a class="button button--purple" href="' +
                presentation +
                item.id +
                '/">Läs mer</a>' +
                "    </div>" +
                '    <div class="bot-view-joblisting__item__logo s-col-3 m-col-3 l-col-3 col-3">' +
                '      <div class="bot-view-joblisting__item__logo__holder">' +
                (item.companylogourl
                    ? '<img src="' + item.companylogourl + '"/>'
                    : "") +
                "      </div>" +
                "    </div>" +
                "  </div>" +
                "</div>"
        );
    });

    $(".bot-view-joblisting").addClass("bot-view-joblisting--animated");
}

function hideResult() {
    $(".bot-view-joblisting").removeClass("bot-view-joblisting--animated");
}

function render(data, filter) {
    // show filters
    $(".js-bot-search-filters").addClass("bot-view-options--animated");

    // show content
    showResult(data);

    var filterText =
        filter.location === "" && filter.trade === ""
            ? "Testa även att filtrera din sökning<br/> efter bransch eller region."
            : "";

    var search_text =
        data.length > 0
            ? `Hurra! Jag hittade <span>${data.length}</span> matchande jobb.<br/> ${filterText}`
            : `Tyvärr hittade jag inga jobb som matchar din sökning! <br/>Testa att utöka din sökning med fler regioner eller branscher.`;
    Helpers.setTypedViewText(search_text, $view);
}

function getAvailableFilters(data) {
    var states = $.map(data, function (item) {
        return item.state;
    });

    var trades = $.map(data, function (item) {
        return item.servicecategory;
    });

    console.log([trades, trades.removeDuplicates().sort()]);

    return {
        states: states.removeDuplicates().sort(),
        trades: trades.removeDuplicates().sort(),
    };
}
function applyFilter(data, filter) {
    var filteredArray = data;

    if (filter.location !== null && filter.location.length > 1)
        filteredArray = data.filter((item) => item.state === filter.location);

    if (filter.trade !== null && filter.trade.length > 1)
        filteredArray = filteredArray.filter(
            (item) => item.servicecategory === filter.trade
        );

    if (filter.text !== null && filter.text.length > 1)
        filteredArray = filteredArray.filter((item) =>
            freeTextSearch(item, filter.text)
        );

    return filteredArray;
}

function freeTextSearch(item, text) {
    // console.log(item);
    let searchText = text.toLowerCase();
    // noinspection OverlyComplexBooleanExpressionJS
    let match =
        (item.title !== undefined &&
            item.title.toLowerCase().indexOf(searchText) > -1) ||
        (item.servicecategory !== undefined &&
            item.servicecategory.toLowerCase().indexOf(searchText) > -1) ||
        (item.employmentextent !== undefined &&
            item.employmentextent.toLowerCase().indexOf(searchText) > -1) ||
        (item.municipality !== undefined &&
            item.municipality.toLowerCase().indexOf(searchText) > -1) ||
        (item.state !== undefined &&
            item.state.toLowerCase().indexOf(searchText) > -1) ||
        (item.company !== undefined &&
            item.company.toLowerCase().indexOf(searchText) > -1);
    if (match) {
        return true;
    }

    let arrayLength = !Helpers.isEmpty(item.descriptions)
        ? item.descriptions.description.length
        : 0;
    for (let i = 0; i < arrayLength; i++) {
        let descriptionItem = item.descriptions.description[i];

        if (
            typeof descriptionItem.descriptiontext === "string" &&
            descriptionItem.descriptiontext.toLowerCase().indexOf(searchText) >
                -1
        ) {
            return true;
        }
    }

    return match;
}

function initDropdowns(filters) {
    var $tradeSelect = $("#search-trade");
    $.each(filters.trades, function (index, value) {
        $tradeSelect.append($("<option></option>").val(value).html(value));
    });

    if (jsCookie.getJSON("filter") !== undefined)
        $tradeSelect.val(jsCookie.getJSON("filter").trade);

    $tradeSelect.formelements("refresh");

    var $locationSelect = $("#search-location");
    $.each(filters.states, function (index, value) {
        $locationSelect.append($("<option></option>").val(value).html(value));
    });

    if (jsCookie.getJSON("filter") !== undefined)
        $locationSelect.val(jsCookie.getJSON("filter").location);

    $locationSelect.formelements("refresh");
}

Array.prototype.removeDuplicates = function () {
    return this.filter(function (item, index, self) {
        return self.indexOf(item) === index;
    });
};
