import JsCookie from "js-cookie";
import Typed from "typed.js";
import Viewhandler from "./Viewhandler.js";
import Helpers from "./helpers.js";
import { init as jobSearch } from "./job-search";
import { triggerSearch as triggerSearch } from "./job-search";

// Wait for Vue to do it's thing
// (Ugly hack to make this legacy code work with Vue rendered content)
$("body").on("vue-loaded", function () {
    //This opens and close the bot-environment
    $(".js-trigger-bot-view").on("click", function () {
        var el = $(this);
        var overlay = $(".expand-bot-bg");
        var background = $(".bot-view-overlay");
        var slug =
            el.data("slug") != undefined && el.data("slug").length > 1
                ? el.data("slug")
                : "start";

        $("body").toggleClass("overflow-hidden");

        if (!el.hasClass("loading")) {
            if (!el.hasClass("open")) {
                $(".search-button").addClass("open");
                el.addClass("open").addClass("loading");
                overlay.addClass("expanded");

                setTimeout(function () {
                    background.addClass("bot-view-overlay--visible");
                    overlay.hide();
                    el.removeClass("loading");
                    vh.openSlug(slug); // open bot-view
                }, 400);
            } else {
                el.addClass("loading");

                vh.close(function () {
                    $(".search-button").removeClass("open");
                    el.removeClass("open");
                    overlay.show();
                    background.removeClass("bot-view-overlay--visible");
                    overlay.removeClass("expanded");

                    setTimeout(function () {
                        el.removeClass("loading");
                    }, 400);
                });
            }
        }
    });

    $(".js-bot-view-open").on("click", function () {
        var slug = $(this).data("slug");
        vh.openSlug(slug);
    });

    $("#register-cv").change(function () {
        var val = $(this).val();
        window.location.href = IP_URL + "/jobb/" + val + "/ansok";
    });

    var vh = new Viewhandler(".bot-view");
    window.vh = vh;

    //This prints sentences in a human way.
    var botTyped = function (selector) {
        if ($(selector).length > 0) {
            var typed = new Typed(selector + " .typed", {
                stringsElement: selector + " .text",
                typeSpeed: 20,
                showCursor: false,
            });
            return typed;
        }
    };

    var botTexts = [
        ".introduction-text",
        ".job-text",
        ".job-listing-text",
        ".contact-text",
        ".interest-text",
        ".contact-info-text",
    ];

    for (const text of botTexts) {
        var $el = $(text);
        $el.html('<div class="text"><p>' + $el.html() + "</p></div>");
        $el.append('<div class="typed"></div>');
    }

    // page close
    var pageClose = function (_this, view, index, cb) {
        $(".bot-view-options").removeClass("bot-view-options--animated");
        view.fadeOut("fast", function () {
            view.removeClass("bot-view-container--visible");
            view.data("typed").destroy();
            view.css({ display: "block" });
            cb(); // for async purposes
        });
    };

    // PAGE 1 - start
    vh.addPage(
        "start",
        ".bot-view-page-start",
        function (_this, view, index, cb) {
            view.hide();
            view.addClass("bot-view-container--visible");
            view.fadeIn("slow");
            var typed = botTyped(".introduction-text");
            view.data("typed", typed);
            view.find(".bot-view-options").addClass(
                "bot-view-options--animated"
            );
            cb(); // for async purposes
        },
        pageClose
    );

    // PAGE 2 - search jobs
    vh.addPage(
        "search",
        ".bot-view-page-search",
        function (_this, view, index, cb) {
            view.hide();
            view.addClass("bot-view-container--visible");
            view.fadeIn("normal");

            var typed = botTyped(".job-text");
            view.data("typed", typed);

            if (JsCookie.get("filter")) {
                $("#search-text").val(JsCookie.getJSON("filter").text);
                $(".js-bot-search-filters").addClass(
                    "bot-view-options--animated"
                );
                triggerSearch();
            }

            $(".js-bot-search-fields").addClass("bot-view-options--animated");
            cb(); // for async purposes
        },
        pageClose
    );

    // PAGE 3 - people
    vh.addPage(
        "people",
        ".bot-view-page-people",
        function (_this, view, index, cb) {
            view.hide();
            view.addClass("bot-view-container--visible");
            view.fadeIn("normal");
            var typed = botTyped(".contact-text");
            view.data("typed", typed);
            $(".bot-view-contact").addClass("bot-view-contact--animated");
            cb(); // for async purposes
        },
        pageClose
    );

    // PAGE 4 - interest
    vh.addPage(
        "interest",
        ".bot-view-page-interest",
        function (_this, view, index, cb) {
            view.hide();
            view.addClass("bot-view-container--visible");
            view.fadeIn("normal");
            var typed = botTyped(".interest-text");
            view.data("typed", typed);
            $(".bot-view-contact").addClass("bot-view-contact--animated");
            cb(); // for async purposes
        },
        pageClose
    );

    // PAGE 5 - contact
    vh.addPage(
        "contact",
        ".bot-view-page-contact",
        function (_this, view, index, cb) {
            view.hide();
            view.addClass("bot-view-container--visible");
            view.fadeIn("normal");
            var typed = botTyped(".contact-info-text");
            view.data("typed", typed);
            cb(); // for async purposes
        },
        pageClose
    );

    function validate(value, type) {
        if (type == "text" || type == "textarea") {
            if (typeof value === "string" && value.length > 0) {
                return true;
            }
        } else if (type == "email") {
            return (
                validate(value, "text") && Helpers.isValidEmailAddress(value)
            );
        }
        return false;
    }

    function getInterestData() {
        var fields = {
            fname: $("#interest_firstname"),
            lname: $("#interest_lastname"),
            email: $("#interest_email"),
            phone: $("#interest_phone"),
        };

        var errors = [];

        // fname
        if (!validate(fields.fname.val(), "text")) {
            errors.push({
                field: fields.fname,
                message: "empty",
            });
        }

        // lname
        if (!validate(fields.lname.val(), "text")) {
            errors.push({
                field: fields.lname,
                message: "empty",
            });
        }

        // email
        if (!validate(fields.email.val(), "email")) {
            errors.push({
                field: fields.email,
                message: "empty",
            });
        }

        // phone has no validation

        return {
            error: errors.length ? true : false,
            errors: errors,
            data: {
                fname: fields.fname.val(),
                lname: fields.lname.val(),
                email: fields.email.val(),
                phone: fields.phone.val(),
            },
        };
    }

    function getPeopleData() {
        var fields = {
            fname: $("#people_firstname"),
            lname: $("#people_lastname"),
            email: $("#people_email"),
            phone: $("#people_phone"),
            text: $("#people_message"),
        };

        var errors = [];

        // fname
        if (!validate(fields.fname.val(), "text")) {
            errors.push({
                field: fields.fname,
                message: "empty",
            });
        }

        // lname
        if (!validate(fields.lname.val(), "text")) {
            errors.push({
                field: fields.lname,
                message: "empty",
            });
        }

        // email
        if (!validate(fields.email.val(), "email")) {
            errors.push({
                field: fields.email,
                message: "empty",
            });
        }

        // phone has no validation

        // text
        if (!validate(fields.text.val(), "text")) {
            errors.push({
                field: fields.text,
                message: "empty",
            });
        }

        return {
            error: errors.length ? true : false,
            errors: errors,
            data: {
                fname: fields.fname.val(),
                lname: fields.lname.val(),
                email: fields.email.val(),
                phone: fields.phone.val(),
                text: fields.text.val(),
            },
        };
    }

    $("textarea.validate, input.validate").change(function () {
        if (validate(this.value, this.type)) this.classList.remove("error");
    });

    $(".js-submit-bot-interest").on("click", function (e) {
        var data = getInterestData();
        e.preventDefault();
        if (data.error) {
            console.log(data.error);
            $.each(data.errors, function (index, error) {
                error.field.addClass("error");
            });
        } else {
            var request = $.ajax({
                url: "/umbraco/api/contact/candidateinterest",
                type: "POST",
                contentType: "application/json; charset=utf-8",
                data: JSON.stringify(data.data),
            });

            request.done(function (data) {
                console.log(data);
                Helpers.setTypedViewText(
                    "Tack f&ouml;r din intresseanm&auml;lan! Vi kontaktar dig s&aring; snart vi kan",
                    vh.pageView("interest")
                );
            });

            request.fail(function (jqXHR) {
                console.log(jqXHR.responseText);
                //TODO:Notify user..
            });
        }
    });

    $(".js-submit-bot-people").on("click", function (e) {
        var data = getPeopleData();
        e.preventDefault();
        if (data.error) {
            console.log(data.error);
            $.each(data.errors, function (index, error) {
                error.field.addClass("error");
            });
        } else {
            var request = $.ajax({
                url: "/wp-json/clockwork/v1/contact-me",
                type: "POST",
                contentType: "application/json; charset=utf-8",
                data: JSON.stringify(data.data),
            });

            request.done(function (data) {
                Helpers.setTypedViewText(
                    "Tack f&ouml;r din kontaktf&ouml;rfr&aring;gan!<br/> Vi kontaktar dig s&aring; snart vi kan",
                    vh.pageView("people")
                );
                var $container = $(".bot-view-page-people");
                $container.animate({ scrollTop: 0 });
                $container
                    .find("input[type=text], input[type=email], textarea")
                    .val("");
            });

            request.fail(function (jqXHR) {
                console.log(jqXHR.responseText);
                //TODO:Notify user..
            });
        }
    });

    jobSearch(vh);
});
