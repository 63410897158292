import Vue from "vue";
import VueResource from "vue-resource";

module.exports = function ()
{
    Vue.use(VueResource);

    new Vue({
        el: "#vue-main",

        data: {
            coworker_firstname: "",
            coworker_lastname: "",
            coworker_fullname: "",
            coworker_title: "",
            coworker_image: "",
            coworker_image_160: "",
            coworker_image_320: "",
        },

        created: async function ()
        {
            try
            {
                const response = await this.$http.post(
                    "/wp-json/clockwork/v1/coworker/",
                    {
                        get: "coworker", // Dummy data to keep mod_security happy :)
                    }
                );

                this.coworker_firstname = response.body.firstname;
                this.coworker_lastname = response.body.lastname;
                this.coworker_fullname = response.body.fullname;
                this.coworker_title = response.body.title;
                this.coworker_image = response.body.image;
                this.coworker_image_160 = response.body.image_160;
                this.coworker_image_320 = response.body.image_320;

                // Ugly hack to allow template to render before the
                // legacy jQuery begins processing it
                setTimeout(function ()
                {
                    $("body").trigger("vue-loaded");
                }, 10);
            } catch (e)
            {
                console.error("Error loading coworker.");
            }
        },
    });
};
